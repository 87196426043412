<template>
    <tr v-if="dataRow">
        <td class="fw-bolder fs-5 text-gray-800" v-if="showSelectMultiple">
          <slot name="selectMultiple"></slot>
        </td>
        <td v-if="requireStatusCol">
            <span v-if="statusRow" class="bullet bullet-vertical bg-success h-30px me-5"></span>
            <span v-if="!statusRow" class="bullet bullet-vertical h-30px me-5"></span>
        </td>
        <td v-for="cell in dataRow.Cells" v-bind:key="cell" :class="{'text-center': cell.Center}">
          <div
            v-if="cell.Badge != '' && cell.Type != 'badgeStatus' "
            class="badge" 
            :class="{'badge-light-success': cell.Badge == 'S', 'badge-light-danger': cell.Badge == 'D'}"
          >
              {{ cell.Value }}
          </div>

          <div
            v-if="cell.Badge != '' && cell.Type == 'badgeStatus' "
            class="badge" 
            :class="{'badge-light-danger': cell.Badge == 'S', 'badge-light-success': cell.Badge == 'D'}"
          >
              {{ cell.Value ? 'No Aprobado' : 'Aprobado' }}
          </div>
          
          <span
            v-if="cell.VisibleInGrid && cell.Type == 'moneda' && cell.Badge == ''"
            :class="{ 'text-hover-primary': cell.CanClicked, 
            'fw-bold': cell.Bold }"  
          >
            {{ formatNumeric(cell.Value) }}
          </span>

          <span
            v-if="cell.VisibleInGrid && cell.Type == 'monedanegativo' && cell.Badge == ''"
            :class="{ 'text-hover-primary': cell.CanClicked, 
                      'fw-bold': cell.Bold,
                      'text-danger': (parseInt(cell.Value) < 0) 
                    }" 
          >
            {{ formatNumeric(cell.Value) }}
          </span>

          <span
            v-if="cell.VisibleInGrid && cell.Type == 'text' && cell.Badge == ''"
            :class="{ 
              'text-hover-primary': cell.CanClicked,
              'fw-bold': cell.Bold}" 
            @click="handleColumnClick(cell.Name)"
          >
            {{ cell.Value }}
          </span>
          <div
            v-if="cell.VisibleInGrid && cell.Type == 'check'"
            class="form-check form-check-custom form-check-solid form-check-sm">
            <input 
              class="form-check-input" 
              type="checkbox"
              disabled
              :checked="cell.Value" 
            />
          </div>
          <span v-if="cell.VisibleInGrid && cell.Type == 'date'">
            {{ formtDate(cell.Value) }}
          </span>
          <span v-if="cell.VisibleInGrid && cell.Type == 'fullDate'">
              {{ formatTimeDate(cell.Value) }}
            </span>
            <span v-if="cell.VisibleInGrid && cell.Type == 'percentage'">
              {{ cell.Value }}%
            </span>
          <span v-if="cell.VisibleInGrid && cell.Type == 'inputNumber'">
            <el-input type="number" min="0" style="width: 100px;" v-model="inputNumberValue" @change="onChangeInputNumber(dataRow.ID)"></el-input>
          </span>
          <span v-if="cell.VisibleInGrid && cell.Type == 'slot'">
            <slot :name="cell.Name"></slot>
          </span>
        </td>
        <td v-if="showCrudActions" style="display: flex; text-align: center;">
          <div v-show="isSaving" class="spinner-border align-middle text-primary" role="status">
          </div>
          <slot></slot>
        </td>
        <td v-if="!showCrudActions"></td>
    </tr>
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount, toRefs } from "vue";


export default defineComponent({
    name: "app-datarow",
    props: {
        dataRow: {
            type: Object,
            required: false
        },
        showCrudActions: {
            type: Boolean,
            required: false,
            default: false
        },
        showSelectMultiple: {
            type: Boolean,
            required: false,
            default: false
        },
        requireStatusCol: {
          type: Boolean,
          required: false,
          default: () => false
        },
        statusPropName: {
          type: String,
          required: false,
          default: () => "isActive"
        },
        callbackCrudAction: Function,
        showProcessingRecord: {
          type: Boolean,
          required: false,
          default: () => true
        },
        textBold: {
            type: Boolean,
            required: false,
            default: false
        },
        callbackColumnClick: Function
    },
    setup(props, context) {
      const { dataRow, statusPropName, showProcessingRecord, textBold } = toRefs(props);

      let inputNumberValue = ref(0);

      const formatNumeric = (number) => {
         var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        });
      return formatter.format(number);
      }
      const handleActionClick = (action) => {
        if(props.callbackCrudAction) {
          props.callbackCrudAction(action, props.dataRow.ID);
        }
      }

      const handleColumnClick = (cellName) => {
        if(props.callbackColumnClick) {
          let cellTmp = dataRow.value.Cells.find(c => c.Name == cellName);
          if(cellTmp && cellTmp.CanClicked) {
            props.callbackColumnClick(dataRow.value.ID);
          }
        }
      }

      const statusRow = computed(() => {
        return dataRow.value[statusPropName.value];
      });

      const isSaving = computed(() => {
        if(showProcessingRecord.value && dataRow.value.showSavingSpinner) {
          return dataRow.value.showSavingSpinner;
        } else return false;
      });

      const activeTextBold = computed(() => textBold.value);

      const formtDate = (strDate) => {
        let date = new Date(strDate);
        let day = `${date.getDate()}`.padStart(2, '0');
        let month = `${date.getMonth() + 1}`.padStart(2, '0');
        return `${day}/${month}/${date.getFullYear()}`;
      }

      const onChangeInputNumber = (rowId) => {
        context.emit("onInputNumberColumnChange", rowId, inputNumberValue.value);
      }

      const formatTimeDate = (strDate) => {
        const date = new Date(strDate);
        const dateFormat = formtDate(strDate);
        const timeFormat = ((date.getHours() < 10) ? "0" : "") + ((date.getHours() > 12) ? (date.getHours() - 12) : date.getHours())
          + ":" + ((date.getMinutes() < 10) ? "0" : "") + date.getMinutes()
          + ":" + ((date.getSeconds() < 10) ? "0" : "") + date.getSeconds() + ((date.getHours() > 12) ? ('PM') : 'AM');

          return `${dateFormat} ${timeFormat}`
      };

      return {
        formatNumeric
        , inputNumberValue
        , statusRow
        , isSaving
        , activeTextBold
        , handleActionClick
        , handleColumnClick
        , formtDate
        , onChangeInputNumber
        , formatTimeDate
      }
    }
});
</script>